<template v-if="content">
	<div class="trip-card">
		<router-link
			:to="`/place/${content.place_id}?type=booking&date_from=${content.date_from}&date_to=${content.date_to}`"
			class="trip-card__link"
		>
			<div v-if="content.images?.length" class="trip-card__img-container">
				<tk-image :src="content.images[0]?.image" />
			</div>

			<div class="trip-card__body">
				<template v-for="itm in statuses" :key="itm.id">
					<p
						v-if="content.status === itm.id"
						:class="['trip-card__status', itm.classes]"
					>
						{{ itm.title }}
					</p>
				</template>

				<h3 class="trip-card__place-name">
					{{ content.title }}
				</h3>
				<p class="trip-card__desc-item">
					Дата:
					<span>
						{{ getTime(content.date_from) }} -
						{{ getTime(content.date_to) }}
					</span>
				</p>
				<p class="trip-card__desc-item">
					Оплачено: <span>{{ content.price.toLocaleString() }} ₽</span>
				</p>
				<p class="trip-card__desc-item">
					Гостей:
					<span>
						{{ getGuestsTextFormat(content.guests) }},
						{{ getChildrenTextFormat(content.children) }}
					</span>
				</p>

				<div class="trip-card__buttons">
					<template v-for="item in links" :key="item.icon">
						<tk-link
							v-show="item.statusesToShow.find((itm) => itm === content.status)"
							:kind="item.kind"
							class="trip-card__button"
							:to="item.to"
						>
							<template v-slot:icon-before>
								<tkSvg :type="item.icon" />
							</template>
							{{ item.title }}
						</tk-link>
					</template>

          <tk-link
            v-if="!this.content.review_date"
            v-show="[5].find((itm) => itm === content.status)"
            kind="common-brown"
            class="trip-card__button"
            :to="`/profile/reviews/guest/${this.content.id}`"
          >
            <template v-slot:icon-before>
              <tkSvg type="review-icon" />
            </template>
            Оставить отзыв
          </tk-link>

					<tk-button
						class="trip-card__button"
						v-if="content.status === 6 || content.status === 7"
						@click.prevent.stop="showReasonModal(content.id)"
					>
						<template v-slot:icon-before>
							<tkSvg type="info" />
						</template>
						Узнать причину
					</tk-button>
				</div>
			</div>
		</router-link>
	</div>
</template>

<script>
import modals from '@/constants/modals'
import { getDateInFormat } from '@/utils/dateTime'
import declension from '@/utils/declension'

export default {
	props: {
		type: {
			type: String,
			default: 'upcoming' // completed or upcoming
		},
		content: {
			type: Object,
			default: () => ({})
		}
	},
	methods: {
		getTime(payload) {
			return getDateInFormat(payload)
		},
		getGuestsTextFormat(payload) {
			return `${payload} ${declension(
				['взрослый', 'взрослых', 'взрослые'],
				+payload
			)}`
		},
		getChildrenTextFormat(payload) {
			return `${payload} ${declension(['ребёнок', 'дети', 'детей'], +payload)}`
		},
		async showReasonModal() {
			const response = await this.$api.reserve.getReasonRejectClient({
				reserve_id: this.content.id
			})

			if (response.response?.message) {
				this.$store.commit('showModal', {
					name: modals.REASON_REJECT_BOOKING,
					isShow: true,
					props: {
						message: response.response.message
					}
				})
			} else {
				this.$store.commit('showNotification', {
					type: 'error',
					text: 'Ошибка получения причины отказа. Обратитесь к администратору!'
				})
			}
		}
	},
	computed: {
		statuses() {
			return [
				{
					title: 'Запрос отправлен',
					classes: 'request-sended',
					id: 1
				},
				{
					title: 'Бронирование подтверждено',
					classes: 'accepted',
					id: 2
				},
				{
					title: 'Активное бронирование',
					classes: 'accepted',
					id: 3
				},
				{
					title: 'Бронирование изменено',
					classes: 'edited',
					id: 4
				},
				{
					title: 'Поездка завершена',
					classes: 'completed',
					id: 5
				},
				{
					title: 'Запрос отклонен',
					classes: 'rejected',
					id: 6
				},
				{
					title: 'Бронирование отменено',
					classes: 'rejected',
					id: 7
				}
			]
		},
		links() {
			return [
				// {
				// 	title: 'Изменить бронирование',
				// 	icon: 'refresh',
				// 	kind: 'common-button',
				// 	to: '/profile/trips/booking-edit/' + this.content.id,
				// 	statusesToShow: [1, 2, 4]
				// },
				{
					title: 'Отменить бронирование',
					icon: 'circle-close',
					kind: 'common-brown',
					to: '/profile/trips/booking-cancel/' + this.content.id,
					statusesToShow: [1, 2, 4]
				},
				{
					title: 'Съездить еще раз',
					icon: 'repeat-trip',
					kind: 'common-button',
					to: `/place/${this.content.place_id}?date_from=${this.content.date_from}&date_to=${this.content.date_to}&guests=${this.content.guests}&children=${this.content.children}`,
					statusesToShow: [5]
				},
				// {
				// 	title: 'Оставить отзыв',
				// 	icon: 'reviewIcon',
				// 	kind: 'common-brown',
				// 	to: `/profile/reviews/guest/${this.content.id}`,
				// 	statusesToShow: [5]
				// }
			]
		}
	}
}
</script>

<style lang="sass">
.trip-card
  position: relative
  width: 100%
  max-width: 310px
  background: #FFFFFF
  border: 1px solid #CBCBCB
  border-radius: 12px
  overflow: hidden
  transition: 0.1s linear

  &:hover
    border: 1px solid #1AC386
    box-shadow: 0px 5px 15px rgba(95, 83, 80, 0.15)

  &__link
    color: #3B3F47

  &__img-container
    position: relative
    height: 215px
    overflow: hidden
    img
      position: absolute
      width: 100%
      height: 100%
      object-fit: cover


  &__body
    padding: 15px 20px
    padding-bottom: 20px

    border-top: 1px solid #CBCBCB

  &__status
    display: inline-block
    padding: 6px 11px
    margin-top: 0
    margin-bottom: 10px

    font-size: 14px
    color: $white
    line-height: 14px

    border-radius: 13px

    &.edited
      background: rgba(64, 134, 244, 0.8)
    &.accepted
      background: #1AC386
    &.request-sended
      background: #FFDA2D
    &.rejected
      background: #E93030
    &.completed
      background: #CBCBCB

  &__place-name
    display: -webkit-box
    -webkit-box-orient: vertical
    -webkit-line-clamp: 2
    min-height: 36px
    margin-top: 0
    margin-bottom: 10px

    font-weight: 600
    font-size: 16px
    line-height: 18px

    overflow: hidden

  &__desc-item
    margin-top: 0
    margin-bottom: 10px

    font-size: 14px
    line-height: 14px

    span
      font-weight: bold

  &__buttons
    margin-top: 15px

  &__button
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    height: 50px
    margin-bottom: 10px

    .btn-ico-before
      display: flex
      align-items: center
      margin-right: 10px

    &:last-child
      margin-bottom: 0
</style>
